//Body
$body-bg: #fff;

//Buttons
$button-enabled: var(--primary_brand);
$button-pressed: #A8C11E;
$button-disabled: #C8C7CC;
$button-focused: #9CB808;
$button-border: 2px solid var(--primary_brand);
$button-radius: 35px;

//Input borders
//$inactive-border: 1px solid #C8C7CC 50%;
$inactive-border: 1px solid #C8C7CC;
$activated-border: 1px solid #B0CB1F;
$error-border: 1px solid #ED3A01;
$error-orange-border: 1px solid #C40000;
$disabled-border: 1px solid #C8C7CC;
$input-radius: 4px;
$input-bg: rgba(255, 255, 255, 0.4);

//Links
$enabled-link: #2B2A29;
$disabled-link: #C8C7CC;
$focused-link: #B0CB1F;

//Icons
$enabled-icon: #B0CB1F;
$disabled-icon: #C8C7CC;
$focused-icon: #9CB808;

// Colors
$greenLime: #B0CB1F;
$greenSwizzle: #F9FBED;
$greenFrench: #C8C7CC;
$grayScorpion: #665E60;
$grayIron: #DCDCDF;
$graySilver: #CAC7C7;
$grayAthens: #EDEDEE;
$gray: #E3E2E5;
$white: #ffffff;
$black: #000000;
$blackTuatara: #2B2A29;
$redMonza: #BC0319;
$redTrinidad: #ED3A01;
$redGuardsman: #C40000;
$yellowTango: #EF8423;
$yellowLace: #FDF2E8;

// Theme colors
$primaryLight: var(--primary_light);
$primaryBrand: var(--primary_brand);
//$primaryDark: var(--primary_dark);
//$tintPrimary: var(--tint_primary);
//$tintSecondary: var(--tint_secondary);
$accentSuccess: var(--accent_success);
$accentError: var(--accent_error);

$logoUrl: var(--logo);
$linkColor: var(--link-color);
