/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
/* Importing Datepicker SCSS file. */
@import "~ngx-bootstrap/datepicker/bs-datepicker";

/* Importing Colors SCSS file. */
@import "styles/colors";

html,
body {
  top: 0 !important;
  height: 100%;
  //max-width: 100vw;
  //overflow-x: hidden;
  overflow-y: auto;
  min-width: 320px;
  min-height: 568px;
  scroll-behavior: smooth;
}

html {
  margin: 0 !important;
}

body {
  min-height: 100%;
  background: $white !important;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  position: relative;
}

.main-container {
  padding: 20px;
  margin: 0 auto;
  z-index: 5;
}

div {
  outline: none;

  &:focus, &:active {
    outline: none;
  }
}

//.hmf-btn {
//  display: flex;
//  justify-content: center;
//
//  z-index: 6;
//  border-radius: $button-radius;
//  color: #fff;
//  font-size: 17px;
//  line-height: 20px;
//  font-weight: 600;
//  border: none;
//  outline: none;
//  //font-family: SF-Pro;
//  padding: 15px 30px;
//  width: 100%;
//  //background: $button-enabled;
//  background: $primaryBrand;
//
//  .mat-icon{
//    margin-right: 5px;
//  }
//}

//.hmf-btn.empty {
//  background: transparent;
//  border: 2px solid $primaryBrand;
//  color: $primaryBrand;
//}

//.hmf-btn:disabled {
//  opacity: 0.5;
//  cursor: unset;
//}

button {
  box-shadow: none !important;
}

button:focus,
button:active:focus,
button.active:focus {
  outline: none !important;
  outline-style: none !important;
}

ul {
  list-style: none;
  margin: 0 !important;
  padding: 0 !important;
}

a {
  color: #000;
  text-decoration: none;
}

form {
  z-index: 5;
  width: 100%;
}

.cdk-overlay-pane {
  max-width: 90vw !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  max-height: 93vh !important;
  background-color: $white !important;
  border-radius: 6px !important;

  .mat-dialog-container {
    padding: 20px !important;
    box-shadow: none !important;
  }
}

.cdk-overlay-pane.full-screen-dialog {
  max-height: 100vh !important;
}

.cdk-overlay-pane:has(snack-bar-container) {
  background-color: transparent !important;
}

.cdk-overlay-pane.full-screen-dialog {
  max-width: unset !important;
  width: 100% !important;
  height: 100%;

  .mat-dialog-container {
    padding: 0 !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 40px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $grayScorpion;
}

// widget
.widget-text {
  color: $grayScorpion !important;
}

.widget-input {
  input {
    border: 1px solid $grayScorpion !important;
    color: $grayScorpion !important;
    caret-color: $grayScorpion !important;
  }

  input::placeholder {
    color: $grayScorpion !important;
  }

  input:-webkit-autofill {
    color: $grayScorpion !important;
    -webkit-box-shadow: 0 0 0 30px rgba(9, 164, 225, 0.1) inset !important;
    -webkit-text-fill-color: $grayScorpion !important;
  }

  mat-icon {
    color: $grayScorpion !important;
  }
}

.widget-border-color {
  border-color: $grayScorpion !important;
}

.phone-help, .phone-help-footer {
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: bold;

  &-title {
    font-size: 18px;
  }

  &-number {
    margin-top: 10px;
    font-size: 25px;

    a {
      text-decoration: none;
    }
  }
}

.phone-help {
  margin: 50px auto;
  display: flex;
  max-width: 327px;
  border-radius: 4px;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  color: $grayScorpion;
  border: 1px solid $grayIron;
  padding: 16px 0;

  &-title {
    font-size: 13px;
  }

  &-number {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      font-size: 17px;
      font-weight: 700;
      color: $primaryBrand;
    }

    .mat-icon {
      display: inline-flex;
      max-width: 18px;
      margin-right: 11px;
      color: $primaryBrand;
    }
  }
}

.phone-help-footer {
  height: 104px;
  padding: 15px 0;
  width: 100%;
  color: $white;
  margin-top: 20px;
  background-color: $primaryBrand;

  .phone-help-title {
    width: 100%;
  }

  .phone-help-number {
    a {
      color: $white !important;
    }
  }
}

@media (max-width: 480px) {
  .phone-help-footer .phone-help-title {
    font-size: 15px;
  }

  .phone-help-footer .phone-help-number {
    font-size: 20px;
  }
}

.mat-icon {
  color: $primaryBrand !important;
}

// SnackBar color
.success-snackbar {
  background-color: $accentSuccess !important;
}

.update-version-snackbar {
  background-color: #4AB7FD !important;
}

.warning-snackbar {
  background-color: $yellowTango !important;
}

.error-snackbar {
  background-color: $accentError !important;
}

.success-snackbar, .warning-snackbar, .error-snackbar , .update-version-snackbar{
  .mat-icon {
    color: $white !important;
  }
}

.test-audio, .audio-element {
  display: block;
  opacity: 0;
  width: 10px;
  height: 10px;
  position: absolute;
  cursor: unset;
  pointer-events: none;
  z-index: -1;
  top: 0;
  left: 0;
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: $primaryBrand !important;
}

.mat-checkbox-layout {
  white-space: pre-wrap !important;
}

.modal-title-g {
  font-family: 'Montserrat', sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 16px !important;
  text-align: center;

  h3 {
    font-size: 18px;
    line-height: 22px;
    font-weight: 800;
    color: #2B2A29;
  }
}

.modal-subtitle-g {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'SF Pro Text', sans-serif;
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 10px;

  h3 {
    margin-left: 5px;
    font-weight: 800;
    font-size: 13px;
    margin-bottom: 0;
  }
}


.modal-description-g {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'SF Pro Text', sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 22px !important;
  margin-bottom: 10px;

  h3 {
    font-weight: 800;
    font-size: 13px;
    margin-bottom: 0;
    margin-left: 5px;
  }
}

.mat-icon.rolling-icon {
  width: 19px !important;
  height: 19px !important;
  color: $white !important;
}

.OT_video-poster {
  background-image: none !important;
}

.OT_audio-level-meter__audio-only-img {
  display: none !important;
}

.required-icon {
  color: red;
  cursor: help;
}

.mat-tooltip-panel {
  background-color: transparent !important;
}

.mat-tooltip {
  margin: 14px 14px 5px 14px !important;
}

.cdk-overlay-container {
  z-index: 3300 !important;
}
